import React, { useEffect } from 'react';
import { Box, emphasize, Typography } from '@mui/material';
import { useTimezones } from '../../../hooks';
import { MediaResponse } from '../../../API';
import { makeStyles } from 'tss-react/mui';
import { SPORTS_LOGO_SIZE, SportsLogo } from '../SportsLogo';
import Localized from '../../shared/Localized';
import { IdBadge } from '../../shared/IdBadge';
import PremiumBadge from '../../shared/PremiumBadge';
import { useData } from '../../../data-layer';
import { useRecoilValue } from 'recoil';
import { DisplayAsOptions } from '../../../utils/types/genericTypes';
import { getMediaAspectRatio } from '../../../utils/styleUtils';
import { alpha } from '@mui/system/colorManipulator';

export interface IMatchListItemProps {
  className?: string;
  match: MediaResponse;
  onClick?: React.MouseEventHandler<HTMLDivElement>;
  showLogos?: boolean;
  showContent?: boolean;
  asList?: boolean;
}

const SPORTS_LOGO_SIZE_LIST = 30;

const useStyles = makeStyles<{ displayAs: DisplayAsOptions; isList: boolean }>()((theme, { displayAs, isList }) => ({
  listItem: {
    display: 'flex',
    alignItems: 'center',
    gap: theme.spacing(2),
    aspectRatio: getMediaAspectRatio(isList ? DisplayAsOptions.LIST : displayAs),
    position: 'relative',
    overflow: 'hidden',
    '&.sortableFiltered': {
      opacity: 0.5,
      filter: 'grayscale(1)',
      cursor: 'default'
    }
  },
  listItemList: {
    '&:hover': {
      background: alpha(theme.palette.divider, 0.05)
    }
  },
  listItemNonList: {
    '& div[class*=listItemLogos]': {
      alignItems: 'start',
      width: '100%',
      justifyContent: 'center'
    },
    '& div[class*=listItemContent]': {
      position: 'absolute',
      bottom: 0,
      margin: 0,
      background: 'rgb(0 0 0 / 70%)',
      backdropFilter: 'blur(2px)',
      color: 'white',
      width: '100%',
      '& span[class*=listItemContentTitle]': {
        fontSize: '12px',
        padding: theme.spacing(1),
        display: 'block',
        lineHeight: '1.3'
      },
      '& div[class*=listItemContentPremiumBadge]': {
        display: 'none'
      },
      '& div[class*=listBottomLine]': {
        display: 'none'
      }
    }
  },
  listItemContent: {
    marginLeft: theme.spacing(4)
  },
  listBottomLine: {
    display: 'flex',
    alignItems: 'center',
    gap: theme.spacing(5)
  },
  listItemLogos: {
    display: 'flex',
    alignItems: 'center',
    gap: theme.spacing(2),
    aspectRatio: getMediaAspectRatio(isList ? DisplayAsOptions.LIST : displayAs),
    background: emphasize(theme.palette.background.paper, 0.1),
    padding: theme.spacing(2),
    borderRadius: theme.shape.borderRadius,
    height: isList ? 50 : 'unset'
  },
  listItemContentTitle: {},
  listItemContentPremiumBadge: {},
  sportsLogo: {
    width: isList ? SPORTS_LOGO_SIZE_LIST : SPORTS_LOGO_SIZE,
    height: isList ? SPORTS_LOGO_SIZE_LIST : SPORTS_LOGO_SIZE
  }
}));

export function MatchListItem({
  className,
  match,
  onClick,
  showLogos = true,
  showContent = true,
  asList = false,
  ...rest
}: IMatchListItemProps): React.ReactElement {
  const {
    media: {
      state: { withRecordById },
      hook: { queueIdToFetch, edit }
    },
    collections: {
      state: { withDisplayAs }
    }
  } = useData();
  const { timestamp } = useTimezones();
  const media = useRecoilValue(withRecordById(match.contentId));
  const displayAs = useRecoilValue(withDisplayAs);
  const isList = asList || displayAs === DisplayAsOptions.LIST;
  const { classes, cx } = useStyles({ displayAs, isList });

  useEffect(() => {
    if (!media) {
      queueIdToFetch(match.contentId);
    }
  }, [match.contentId]);

  const onEdit = () => {
    edit(match.contentId);
  };

  return (
    <div
      className={`${cx({
        [classes.listItem]: true,
        [classes.listItemList]: isList,
        [classes.listItemNonList]: !isList
      })} ${className}`}
      onClick={onClick ?? onEdit}
      {...rest}
    >
      {showLogos && (
        <Box className={classes.listItemLogos}>
          <SportsLogo logoImagePath={match.homeTeam?.logo} className={classes.sportsLogo} />
          <SportsLogo logoImagePath={match.awayTeam?.logo} className={classes.sportsLogo} />
        </Box>
      )}
      {showContent && (
        <div className={classes.listItemContent}>
          <div>
            {match.title && <Localized prop={match.title} className={classes.listItemContentTitle} />}
            {match.isPremium && <PremiumBadge className={classes.listItemContentPremiumBadge} />}
          </div>
          <div className={classes.listBottomLine}>
            <Typography component="span" variant="body2" color="textSecondary">
              {match.kickoffDate && timestamp(match.kickoffDate)}
            </Typography>
            {match.sportsEvent && <IdBadge id={match.sportsEvent.sportsEventId} showIdLabel />}
          </div>
        </div>
      )}
    </div>
  );
}
