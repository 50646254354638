import React, { forwardRef } from 'react';
import { Box, FormControl, InputLabel, MenuItem, Stack, Typography } from '@mui/material';
import { Controller, useFormContext } from 'react-hook-form';
import { useRecoilValue } from 'recoil';
import { MenuActionType, MenuIconName, MenuItemUiModuleBody } from '../../../../../API';
import { useLocales, useTheme } from '../../../../../hooks';
import {
  withLayoutFormMetadata,
  withSelectedLayoutId,
  withSelectedLayoutUIModules
} from '../../../../../state/Layouts';
import { CustomValidators } from '../../../../../utils/customValidators';
import AssetBrowser from '../../../../Assets/AssetBrowser';
import TextValidator from '../../../../shared/TextValidator';
import { FormBody, FormField } from '../../styles';
import { UIModuleTypeForm } from '../../UIModuleForm';
import { PagesAutocomplete } from '../PagesAutocomplete';
import { markAsRequired } from '../../../../../utils/formHelpers';
import { NONE } from '../../../../../utils/types/genericTypes';
import { LokaliseAutocomplete } from '../../../../shared/LokaliseAutocomplete';
import { CustomIcon } from '../../../../shared/CustomIcon';
import { menuIconPaths, menuIconViewbox } from '../../../../../utils/menuIcons';
import { AssetTypesEnum } from '../../../../../utils/assetTypes';
import Select from '../../../../shared/Select';

export const testIds = {
  formBody: 'menu-item-form.form-body',
  actionType: 'menu-item-form.treatment-selector',
  actionTypeItem: 'menu-item-form.treatment-selector-item',
  targetSelector: 'menu-item-form.target-selector',
  targetSelectorItem: 'menu-item-form.target-selector-item',
  pagesAutocomplete: 'menu-item-form.pages-autocomplete',
  linkInput: 'menu-item-form.link-input',
  keySelector: 'hero-message.key-selector',
  keyOption: 'hero-message.key-option'
};

const MenuItemForm = forwardRef<UIModuleTypeForm<MenuItemUiModuleBody>>(() => {
  const { control, watch } = useFormContext<MenuItemUiModuleBody>();
  const { t } = useLocales();
  const { formControlColor } = useTheme();

  const actionType = watch('menuActionType');

  const formMetadata = useRecoilValue(withLayoutFormMetadata);
  const uiModules = useRecoilValue(withSelectedLayoutUIModules);
  const selectedLayoutId = useRecoilValue(withSelectedLayoutId);
  const formMetadataIndex = Array.isArray(formMetadata.index) ? formMetadata.index[1] : formMetadata.index;
  const currentMenuItemIndex = formMetadataIndex >= 0 ? formMetadata.index : uiModules?.length || 0;
  const currentMenuItemIconId = `${selectedLayoutId}-${currentMenuItemIndex}`;

  return (
    <FormBody data-testid={testIds.formBody}>
      <FormField>
        <Controller
          control={control}
          name="titleLokaliseKey"
          render={({ field: { value, name, onChange } }) => (
            <LokaliseAutocomplete
              label={t('general.title')}
              name={name}
              value={value as string}
              data-testid={testIds.keySelector}
              onChange={onChange}
            />
          )}
        />
      </FormField>
      <FormField>
        <FormControl color={formControlColor}>
          <InputLabel id="actionTypeSelector">{t('layouts.action_type')}</InputLabel>
          <Controller
            control={control}
            name="menuActionType"
            render={({ field: { value, onChange } }) => (
              <Select
                label={t('layouts.action_type')}
                labelId="actionTypeSelector"
                value={value}
                onChange={onChange}
                data-testid={testIds.actionType}
              >
                {Object.values(MenuActionType).map((action) => (
                  <MenuItem
                    key={action}
                    value={action}
                    data-testid={testIds.actionTypeItem}
                    data-action-type-id={action}
                  >
                    {action}
                  </MenuItem>
                ))}
              </Select>
            )}
          />
        </FormControl>
      </FormField>
      <FormField>
        <FormControl color={formControlColor} required>
          {actionType === MenuActionType.PAGE && (
            <Controller
              control={control}
              name="targetId"
              render={({ field: { value, onChange } }) => (
                <PagesAutocomplete
                  value={value}
                  onChange={onChange}
                  renderInput={(params) => (
                    <TextValidator
                      value={value}
                      {...params}
                      name="targetId"
                      label={markAsRequired(t('layouts.target'))}
                      color={formControlColor}
                      validators={['required']}
                      errorMessages={[t('general.field_is_required')]}
                    />
                  )}
                  data-testid={testIds.pagesAutocomplete}
                />
              )}
            />
          )}
          {actionType === MenuActionType.LINK && (
            <Controller
              control={control}
              name="targetId"
              render={({ field: { value, onChange } }) => (
                <TextValidator
                  name="targetId"
                  color={formControlColor}
                  label={t('layouts.target')}
                  value={value}
                  onChange={onChange}
                  validators={['required', CustomValidators.isValidUrl]}
                  errorMessages={[t('general.field_is_required'), t('general.field_match_url')]}
                  data-testid={testIds.linkInput}
                />
              )}
            />
          )}
        </FormControl>
      </FormField>
      <FormField>
        <FormControl color={formControlColor} required>
          <InputLabel id="iconNameSelector">{t('layouts.menu_item_icon_name')}</InputLabel>
          <Controller
            control={control}
            name="iconName"
            render={({ field: { value, onChange } }) => (
              <Select
                label={t('layouts.menu_item_icon_name')}
                labelId="iconNameSelector"
                value={value || NONE}
                onChange={onChange}
              >
                <MenuItem value={NONE}>
                  <em>{t('general.none')}</em>
                </MenuItem>
                {Object.values(MenuIconName).map((name) => (
                  <MenuItem key={name} value={name}>
                    <Stack direction="row" gap={3}>
                      <Box sx={{ width: 22, display: 'flex' }}>
                        <CustomIcon pathString={menuIconPaths[name] as string} viewBox={menuIconViewbox} />
                      </Box>
                      {name}
                    </Stack>
                  </MenuItem>
                ))}
              </Select>
            )}
          />
        </FormControl>
      </FormField>
      <FormField>
        <Typography color="textSecondary" variant="body2">
          {t('layouts.menu_item_icon_image')}
        </Typography>
        <Controller
          control={control}
          name="iconImage"
          render={({ field: { value, onChange } }) => (
            <AssetBrowser
              value={value || ''}
              assetId={currentMenuItemIconId || ''}
              onChange={onChange}
              assetType={AssetTypesEnum.MENU_ITEM_LOGO}
            />
          )}
        />
      </FormField>
    </FormBody>
  );
});

MenuItemForm.displayName = 'MenuItemForm';

export default MenuItemForm;
