import React, { useEffect, useState } from 'react';
import { AddOutlined, Search } from '@mui/icons-material';
import { InputAdornment, List, Stack, Typography } from '@mui/material';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import { useRecoilState, useRecoilValue } from 'recoil';
import { makeStyles } from 'tss-react/mui';
import { DataPresetResponse, DataPresetType, PaginationMeta } from '../../../API';
import { useData } from '../../../data-layer';
import { useLocales } from '../../../hooks';
import { collectionsHeaderHeight } from '../../../utils/consts/collectionsConsts';
import { getMediaGridTemplateColumns } from '../../../utils/styleUtils';
import { DisplayAsOptions } from '../../../utils/types/genericTypes';
import DisplayAs from '../../Collections/DisplayAs';
import Button from '../../shared/Button';
import Pagination from '../../shared/Pagination';
import ShadowScroller from '../../shared/ShadowScroller';
import TextField from '../../shared/TextField';
import { PresetListItem } from '../PresetListItem';
import { DataPresetFilters } from '../../../API';
import { Sortable, SortableGroups } from '../../shared/Sortable';
import { noop } from 'lodash-es';
import { PresetsSkeleton } from '../PresetsSkeleton';
import PaginationResults from '../../shared/Pagination/PaginationResults';
import { HPC_FILTER_HEIGHT } from '../HPC';
import { usePermissions } from '../../../hooks/Permissions/usePermissions';
import { PermissionsLevel } from '../../../utils/types/permissionsTypes';
import { usePermissionsGuard } from '../../../hooks/General/usePermissionsGuard';
import { HomepageOptions } from '../../../state/theme';

export const testIds = {
  root: 'preset-search.root',
  name: 'preset-search.name',
  searchButton: 'preset-search.search-button',
  loader: 'preset-search.loader',
  newButton: 'preset-search.new-button'
};

const useStyles = makeStyles<{ displayAs: DisplayAsOptions }>()((theme, { displayAs }) => ({
  form: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%'
  },
  topbar: {
    display: 'flex',
    alignItems: 'center',
    height: collectionsHeaderHeight,
    background: theme.palette.background.paper,
    padding: theme.spacing(3, 4),
    gap: theme.spacing(2),
    borderBottom: `1px solid ${theme.palette.divider}`
  },
  separator: {
    flexGrow: 1
  },
  textField: {
    '&.MuiTextField-root': {
      marginBottom: '0 !important'
    }
  },
  searchResults: {
    flexGrow: 1,
    height: '100%'
  },
  searchContainer: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(4),
    padding: theme.spacing(4),
    borderBottom: `1px solid ${theme.palette.divider}`
  },
  presetsContainer: {
    display: 'grid',
    minHeight: '100%',
    overflow: 'hidden',
    padding: theme.spacing(2),
    gridGap: theme.spacing(2),
    gridTemplateColumns: getMediaGridTemplateColumns(displayAs),
    gridTemplateRows: 'repeat(auto-fit, minmax(0, max-content))'
  },
  bottomSection: {
    background: theme.palette.background.paper,
    borderTop: `1px solid ${theme.palette.divider}`,
    color: theme.palette.text.secondary,
    padding: theme.spacing(3, 4)
  },
  loadingList: {
    display: 'flex',
    flexDirection: 'column',
    padding: theme.spacing(2),
    gap: theme.spacing(2)
  }
}));

export function PresetSearch(): React.ReactElement {
  const { canSave } = usePermissionsGuard({
    homepageOption: HomepageOptions.HPC
  });
  const {
    heroPresetCollections: {
      state: { withDisplayAs }
    },
    presets: {
      state: { withSearchParams, withLastCreatedPreset },
      hook: { searchPresets, new: newPreset }
    }
  } = useData();

  const { hasAnyPermission } = usePermissions();
  const [searchParams, setSearchParams] = useRecoilState(withSearchParams);
  const displayAs = useRecoilValue(withDisplayAs);
  const { classes, cx } = useStyles({ displayAs });
  const { t } = useLocales();
  const methods = useForm<DataPresetFilters>();
  const { control, handleSubmit, reset, setValue } = methods;
  const [isLoading, setIsLoading] = useState(false);
  const [meta, setMeta] = useState<PaginationMeta>();
  const [presets, setPresets] = useState<DataPresetResponse[]>([]);
  const lastCreatedPreset = useRecoilValue(withLastCreatedPreset);

  const isList = displayAs === DisplayAsOptions.LIST;

  useEffect(() => {
    if (!presets.length) {
      reset(searchParams);
      onSubmit();
    }
  }, []);

  useEffect(() => {
    if (presets && lastCreatedPreset) {
      setPresets([lastCreatedPreset, ...presets]);
    }
  }, [lastCreatedPreset]);

  const handleSearch = async (data: DataPresetFilters) => {
    setIsLoading(true);
    const response = await searchPresets({
      ...data,
      type: [
        DataPresetType.HERO,
        DataPresetType.HERO_ITEM_VOD,
        DataPresetType.HERO_ITEM_CHANNEL,
        DataPresetType.HERO_ITEM_EVENTS,
        DataPresetType.HERO_ITEM_COLLECTION_LINK,
        DataPresetType.HERO_ITEM_PAGE,
        DataPresetType.HERO_ITEM_PROMOTION
      ]
    });
    setIsLoading(false);
    if (response) {
      setMeta(response.meta);
      setPresets(response.results);
      const params = { ...data, limit: response.meta.limit, page: response.meta.page };
      setSearchParams(params);
      reset(params);
    }
  };

  const handleNewSearch = async (data: DataPresetFilters) => handleSearch({ ...data, page: 1 });

  const onSubmit = (event?: React.FormEvent<HTMLElement>) => {
    if (event) {
      event.preventDefault();
      event.stopPropagation();
    }
    handleSubmit(handleSearch)();
  };

  const onSubmitNew = (event?: React.FormEvent<HTMLElement>) => {
    if (event) {
      event.preventDefault();
      event.stopPropagation();
    }
    handleSubmit(handleNewSearch)();
  };

  return (
    <form onSubmit={onSubmitNew} className={classes.form} data-testid={testIds.root}>
      <FormProvider {...methods}>
        <div className={classes.topbar}>
          <Typography variant="h5">{t('hpc.hero_presets')}</Typography>
          <div className={classes.separator} />
          <DisplayAs state={withDisplayAs} />
        </div>
        <div className={classes.searchContainer}>
          <Stack direction="row" gap={4} alignItems="center">
            <Controller
              control={control}
              name="name"
              render={({ field: { value, onChange } }) => (
                <TextField
                  value={value}
                  onChange={onChange}
                  className={classes.textField}
                  fullWidth
                  clearable
                  label={t('collections.search_by_keywords')}
                  onClear={onSubmitNew}
                  data-testid={testIds.name}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <Search />
                      </InputAdornment>
                    )
                  }}
                />
              )}
            />
            <Controller
              control={control}
              name="contentId"
              render={({ field: { value, onChange } }) => (
                <TextField
                  value={value}
                  onChange={onChange}
                  className={classes.textField}
                  fullWidth
                  clearable
                  label={t('collections.search_by_content_id')}
                  onClear={onSubmitNew}
                />
              )}
            />
            <Button type="submit" data-testid={testIds.searchButton}>
              {t('general.search')}
            </Button>
          </Stack>
          <Stack direction="row" alignItems="center" justifyContent="space-between" sx={{ height: HPC_FILTER_HEIGHT }}>
            <PaginationResults list={presets} meta={meta} isLoading={isLoading} />
          </Stack>
        </div>
        <div className={classes.searchResults}>
          <ShadowScroller loading={isLoading}>
            <List disablePadding>
              {!isLoading && (
                <Sortable
                  className={cx({ [classes.presetsContainer]: !isList, [classes.loadingList]: isList && isLoading })}
                  group={{ name: SortableGroups.presets, pull: 'clone', put: false }}
                  sort={false}
                  setList={noop}
                  list={presets.map((preset) => preset.id)}
                  isStringList
                  ghostClass="sortableGhost"
                  dragClass="sortableDragPaper"
                  filter=".sortableFiltered"
                >
                  {presets.map((preset) => (
                    <PresetListItem key={preset.id} presetId={preset.id as string} />
                  ))}
                </Sortable>
              )}
              {isLoading && <PresetsSkeleton data-testid={testIds.loader} />}
            </List>
          </ShadowScroller>
        </div>
        <Stack direction="row" alignItems="center" className={classes.bottomSection}>
          <Button
            endIcon={<AddOutlined />}
            onClick={() => newPreset()}
            disabled={!hasAnyPermission(PermissionsLevel.READ) || !canSave}
            size="medium"
            data-testid={testIds.newButton}
          >
            {t('presets.new_preset')}
          </Button>
          <Pagination
            limitOptions={[20, 50]}
            queryMetaData={meta}
            onPageChange={(newPage: number) => {
              reset();
              setValue('page', newPage);
              onSubmit();
            }}
            onResultsChange={(newLimit: number) => {
              reset();
              setValue('page', 1);
              setValue('limit', newLimit);
              onSubmit();
            }}
          />
        </Stack>
      </FormProvider>
    </form>
  );
}
