/* tslint:disable */
/* eslint-disable */
/**
 * CMS API
 * The CMS API!
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */



/**
 * 
 * @export
 * @enum {string}
 */

export enum HeroModuleItemType {
    VOD = 'VOD',
    CHANNEL = 'CHANNEL',
    EVENTS = 'EVENTS',
    PAGE = 'PAGE',
    COLLECTION_LINK = 'COLLECTION_LINK',
    PROMOTION = 'PROMOTION'
}



