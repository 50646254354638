import React, { forwardRef } from 'react';
import { Controller, useFormContext, Path } from 'react-hook-form';
import { ProfileUiModuleBody } from '../../../../../API';
import { useLocales, useTheme } from '../../../../../hooks';
import { LocaleKeys } from '../../../../../locales/i18n';
import { AssetTypes, AssetTypesEnum } from '../../../../../utils/assetTypes';
import { markAsRequired } from '../../../../../utils/formHelpers';
import AssetBrowser from '../../../../Assets/AssetBrowser';
import { LokaliseAutocomplete } from '../../../../shared/LokaliseAutocomplete';
import { FormBody, FormField, SectionHeader } from '../../styles';
import { UIModuleTypeForm } from '../../UIModuleForm';
import { FormControlLabel, Switch } from '@mui/material';

export const testIds = {
  formBody: 'profile.form-body',
  formField: (name: Path<ProfileUiModuleBody>): string => `profile.form-field.${name}`
};

type ProfileFieldProps = {
  name: Path<ProfileUiModuleBody>;
  required?: boolean;
  'data-testid'?: string;
};

const ProfileForm = forwardRef<UIModuleTypeForm<ProfileUiModuleBody>>(() => {
  const { control, watch } = useFormContext<ProfileUiModuleBody>();
  const { t } = useLocales();
  const { formControlColor } = useTheme();

  const moduleId = watch('moduleId');

  const AssetPicker = ({
    name,
    assetType,
    required = false
  }: ProfileFieldProps & { assetType: AssetTypesEnum }): React.ReactElement => (
    <Controller
      control={control}
      name={name}
      rules={{ required }}
      render={({ field: { value, onChange }, fieldState: { error } }) => (
        <FormField>
          <AssetBrowser
            label={markAsRequired(t(`layouts.profile.fields.${name}`), required)}
            assetId={moduleId || ''}
            assetType={assetType}
            value={value as string}
            data-testid={testIds.formField(name)}
            hasError={!!error}
            onChange={onChange}
          />
        </FormField>
      )}
    />
  );

  const LokaliseField = (name: Path<ProfileUiModuleBody>, required = false): React.ReactElement => (
    <FormField>
      <Controller
        control={control}
        name={name}
        render={({ field: { value, onChange } }) => (
          <LokaliseAutocomplete
            label={t(`layouts.profile.fields.${name}` as LocaleKeys)}
            name={name}
            value={value as string}
            onChange={onChange}
            required={required}
            data-testid={testIds.formField(name)}
          />
        )}
      />
    </FormField>
  );

  const SwitchField = ({ name }: ProfileFieldProps): React.ReactElement => (
    <FormField>
      <Controller
        control={control}
        name={name}
        render={({ field: { value, onChange } }) => (
          <FormControlLabel
            label={t(`layouts.profile.fields.${name}` as LocaleKeys)}
            control={
              <Switch
                color={formControlColor}
                checked={value as boolean}
                onChange={onChange}
                data-testid={testIds.formField(name)}
              />
            }
          />
        )}
      />
    </FormField>
  );

  return (
    <FormBody data-testid={testIds.formBody}>
      <div>
        <SectionHeader>{t('layouts.profile.headers.images')}</SectionHeader>
        <AssetPicker name="landscapeFillImage" assetType={AssetTypes.profile.landscape} required />
        <AssetPicker name="portraitFillImage" assetType={AssetTypes.profile.portrait} required />
        <AssetPicker name="desktopFillImage" assetType={AssetTypes.profile.desktop} />
        <AssetPicker name="mobileFillImage" assetType={AssetTypes.profile.mobile} />
        <AssetPicker name="ctvFillImage" assetType={AssetTypes.profile.ctv} />
        <AssetPicker name="tabletFillImage" assetType={AssetTypes.profile.tablet} />
      </div>
      <div>
        <SectionHeader>{t('layouts.profile.headers.profile')}</SectionHeader>
        {LokaliseField('header', true)}
        {LokaliseField('subheader', true)}
        {LokaliseField('benefit')}
        {LokaliseField('adminMiCuenta')}
        {LokaliseField('subscribeCta')}
        {LokaliseField('subscribeCtaDestination')}
        {LokaliseField('signUpCta')}
        {LokaliseField('signInCta')}
        {LokaliseField('reactivateCta')}
        {LokaliseField('reactivateCtaDestination')}
      </div>
      <div>
        <SectionHeader>{t('layouts.profile.headers.reminder')}</SectionHeader>
        {LokaliseField('bundleReminderPrimaryOpt')}
        {LokaliseField('bundleReminderSecondaryOpt')}
        {LokaliseField('bundleCta')}
      </div>
      <div>
        <SectionHeader>{t('layouts.profile.headers.my_account')}</SectionHeader>
        {LokaliseField('planNameTag')}
        {LokaliseField('planStatusTag')}
        {LokaliseField('paymentMethodTag')}
        {LokaliseField('paymentStatus')}
        {LokaliseField('paymentStatusAlert')}
        {LokaliseField('planSwitchingCta')}
        {LokaliseField('changePaymentMethodCta')}
        {LokaliseField('cancelSubscriptionCta')}
        {LokaliseField('planSwitchingHeaderCard')}
        {LokaliseField('planSwitchingCardCta')}
        {LokaliseField('legalDisclosurePlanSwitchingCard')}
        {LokaliseField('changeEmailCta')}
        {LokaliseField('changePasswordCta')}
        {LokaliseField('planDetailsCta')}
        {LokaliseField('cancelInstructionsIap')}
      </div>
      <div>
        <SectionHeader>{t('layouts.profile.headers.confirmation')}</SectionHeader>
        {LokaliseField('newPlanNameTag')}
        {LokaliseField('confirmationPaymentStatusAlert')}
        {LokaliseField('planSwitchingRefundTag')}
        {LokaliseField('totalAmountDueTag')}
        {LokaliseField('planSwitchDetailSubheader')}
        {LokaliseField('activationDateDetail')}
        {LokaliseField('newBenefitsDetail')}
        {LokaliseField('planSwitchLegalDisclosure')}
        {LokaliseField('planSwitchConfirmCta')}
        {LokaliseField('planSwitchCancelCta')}
        <div></div>
        <SwitchField name="legalCheckbox" />
      </div>
      <div>
        <SectionHeader>{t('layouts.profile.headers.spin_premia')}</SectionHeader>
        {LokaliseField('spinPremia.headerBefore')}
        {LokaliseField('spinPremia.ctaBefore')}
        {LokaliseField('spinPremia.headerAfter')}
        {LokaliseField('spinPremia.bodyAfter')}
        {LokaliseField('spinPremia.ctaAfter')}
      </div>
      <div>
        <SectionHeader>{t('layouts.profile.headers.gift_cards')}</SectionHeader>
        {LokaliseField('paymentMethodTitle')}
        {LokaliseField('paymentMethodDescription')}
        {LokaliseField('giftCardBalance')}
        {LokaliseField('topUpCta')}
        {LokaliseField('redeemTitle')}
        {LokaliseField('redeemCta')}
        {LokaliseField('giftCardBalanceHeading')}
        {LokaliseField('giftCardBalanceDtc')}
      </div>
    </FormBody>
  );
});

ProfileForm.displayName = 'ProfileForm';

export default ProfileForm;
