import React, { SyntheticEvent, useEffect, useMemo, useState } from 'react';
import { AddToPhotos, CheckCircle, Edit, Pending, Undo } from '@mui/icons-material';
import { Autocomplete, InputAdornment, TextField } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import { debounce } from 'lodash-es';
import { DataPresetBody, DataPresetResponse, DataPresetType } from '../../../API';
import { useLocales, useTheme } from '../../../hooks';
import { getHashFromObject } from '../../../utils/generateHash';
import IconButton from '../IconButton';
import { PresetModal } from './PresetModal';
import { useRecoilState } from 'recoil';
import { useData } from '../../../data-layer';

export interface IPresetsProps {
  'data-testid'?: string;
  presetType?: DataPresetType;
  isValid?: boolean;
  data?: PresetDataType;
  onChange: (data: PresetDataType) => void;
  hideControls?: boolean;
}

export type PresetDataType = DataPresetBody['data'];

const useStyles = makeStyles()((theme) => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    gap: theme.spacing(2)
  },
  autocomplete: {
    minWidth: 150,
    flexGrow: 1
  }
}));

export const testIds = {
  autocomplete: 'presets-autocomplete',
  autocompleteOption: 'presets-autocompleteOption'
};

export const DUMMY_PRESET_NAME = '__DUMMY_PRESET__';

export function Presets({
  data,
  onChange,
  presetType = DataPresetType.HERO,
  isValid = true,
  hideControls = false,
  ...props
}: IPresetsProps): React.ReactElement {
  const {
    presets: {
      state: { withDefaultPresets },
      hook: { getPresetsByType, getPresetByHash }
    }
  } = useData();

  const { classes } = useStyles();
  const { t } = useLocales();
  const { formControlColor } = useTheme();

  const dummyPreset: DataPresetResponse = {
    id: DUMMY_PRESET_NAME,
    name: DUMMY_PRESET_NAME,
    type: presetType,
    data: {} as PresetDataType,
    hash: ''
  };

  const [dataHash, setDataHash] = useState<string>();
  const [initialDataHash, setInitialDataHash] = useState<string>();
  const [selectedPreset, setSelectedPreset] = useState<DataPresetResponse>(dummyPreset);
  const [filteredPresets, setFilteredPresets] = useState<DataPresetResponse[]>();
  const [isLoading, setIsLoading] = useState(false);
  const [formPreset, setFormPreset] = useState<DataPresetBody | DataPresetResponse>();
  const [defaultPresets, setDefaultPresets] = useRecoilState(withDefaultPresets);
  const isClean = useMemo(
    () => selectedPreset.id !== dummyPreset.id && dataHash === initialDataHash,
    [dataHash, initialDataHash, selectedPreset]
  );

  useEffect(() => {
    searchForPresetMatch();
  }, []);

  useEffect(() => {
    if (data) {
      setDataHash(getHashFromObject(data));
    }
  }, [data]);

  const searchForPresetMatch = async () => {
    if (!data) return;
    const hash = getHashFromObject(data);
    setInitialDataHash(hash);
    try {
      const preset = await getPresetByHash(hash);
      if (preset) setSelectedPreset(preset);
    } catch (err) {}
  };

  const onNewClick = () => {
    setFormPreset({
      name: '',
      type: data?.type || presetType,
      data: data || ({} as PresetDataType)
    });
  };

  const onEditClick = () => {
    setFormPreset({ ...selectedPreset, data: data || ({} as PresetDataType) });
  };

  const searchPresets = async (query: string, forceLoad = false) => {
    let results: DataPresetResponse[] = [];
    if (!forceLoad && !query && defaultPresets[presetType]?.length) {
      results = defaultPresets[presetType];
    }
    if (!results.length) {
      setIsLoading(true);
      const data = await getPresetsByType(presetType, query);
      results = data?.results || [];
      if (!query) {
        setDefaultPresets({ ...defaultPresets, [presetType]: results });
      }
    }
    if (selectedPreset.name !== DUMMY_PRESET_NAME) {
      setFilteredPresets([selectedPreset, ...results.filter((item) => item.id !== selectedPreset.id)]);
    } else {
      setFilteredPresets(results);
    }
    setIsLoading(false);
  };

  const searchPresetsDebounced = debounce(searchPresets, 300);

  const onInputChange = (event: SyntheticEvent, query: string) => {
    if (!event) return;
    if (event.type === 'change') {
      searchPresetsDebounced(query);
    }
  };

  const onValueChange = (event: SyntheticEvent, changedValue: DataPresetResponse) => {
    setInitialDataHash(changedValue.hash);
    setDataHash(changedValue.hash);
    setSelectedPreset(changedValue);
    onChange(changedValue.data);
  };

  const onInputFocus = () => {
    if (!isLoading) {
      searchPresetsDebounced('');
    }
  };

  const onPresetSaved = (preset: DataPresetResponse, isNew: boolean) => {
    setInitialDataHash(preset.hash);
    setDataHash(preset.hash);
    setSelectedPreset(preset);
    searchPresets('', true);
    if (!filteredPresets) return;
    if (isNew) {
      setFilteredPresets([preset, ...filteredPresets]);
    } else {
      const presetIndex = filteredPresets.findIndex((filteredPreset) => preset.hash === filteredPreset.hash);
      if (presetIndex >= 0) {
        const newFilteredPresets = [...filteredPresets];
        newFilteredPresets[presetIndex] = preset;
        setFilteredPresets(newFilteredPresets);
      }
    }
  };

  const onPresetFormClosed = () => {
    setFormPreset(undefined);
  };

  const undoChanges = (event: SyntheticEvent) => {
    onValueChange(event, selectedPreset);
  };

  return (
    <>
      <div className={classes.root} data-testid={props['data-testid']}>
        <Autocomplete
          role="listbox"
          options={filteredPresets || []}
          loading={isLoading}
          loadingText={t('general.loading')}
          getOptionLabel={(preset) =>
            preset.name === DUMMY_PRESET_NAME ? t('presets.dummy_preset_text') : preset.name
          }
          disableClearable
          renderOption={(props, option) => {
            return (
              <li {...props} data-testid={testIds.autocompleteOption} data-option={option} key={option.name}>
                {option.name}
              </li>
            );
          }}
          renderInput={(params) => {
            const inputProps = { ...params.InputProps };
            if (!hideControls && selectedPreset.name !== DUMMY_PRESET_NAME) {
              inputProps.startAdornment = (
                <InputAdornment position="start">
                  {isClean && <CheckCircle fontSize="small" />}
                  {!isClean && <Pending fontSize="small" />}
                </InputAdornment>
              );
            }

            return (
              <TextField
                {...params}
                color={formControlColor}
                onFocus={onInputFocus}
                InputProps={inputProps}
                label={t('presets.preset')}
              />
            );
          }}
          value={selectedPreset}
          onChange={onValueChange}
          onInputChange={onInputChange}
          className={classes.autocomplete}
          isOptionEqualToValue={(option, value) => option.name === value.name}
          data-testid={testIds.autocomplete}
        />
        {!hideControls && (
          <div>
            <IconButton
              disabled={selectedPreset.name === DUMMY_PRESET_NAME || isClean}
              onClick={undoChanges}
              title={t('presets.undo_changes')}
            >
              <Undo />
            </IconButton>
            <IconButton disabled={isClean || !isValid} onClick={onNewClick} title={t('presets.save_as_new')}>
              <AddToPhotos />
            </IconButton>
            <IconButton
              disabled={selectedPreset.name === DUMMY_PRESET_NAME || !isValid}
              onClick={onEditClick}
              title={t('presets.edit_existing_preset')}
            >
              <Edit />
            </IconButton>
          </div>
        )}
      </div>
      <PresetModal formPreset={formPreset} saveCallback={onPresetSaved} onClose={onPresetFormClosed} />
    </>
  );
}
