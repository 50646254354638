/* tslint:disable */
/* eslint-disable */
/**
 * CMS API
 * The CMS API!
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */



/**
 * 
 * @export
 * @enum {string}
 */

export enum ContentListUIModuleContentTreatment {
    PORTRAIT = 'PORTRAIT',
    PORTRAIT_LARGE = 'PORTRAIT_LARGE',
    LANDSCAPE = 'LANDSCAPE',
    LANDSCAPE_WITH_SUBTITLE = 'LANDSCAPE_WITH_SUBTITLE',
    VERTICAL_LIST = 'VERTICAL_LIST',
    GRID = 'GRID',
    HORIZONTAL_LOGO_LIST = 'HORIZONTAL_LOGO_LIST'
}



