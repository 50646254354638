import { AppRegistration, Check, FilterAlt, MoreVertOutlined } from '@mui/icons-material';
import {
  Badge,
  ListItemButton,
  ListItemText,
  Popover,
  MenuItem,
  ListItem,
  ListItemSecondaryAction
} from '@mui/material';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { useData } from '../../../../../data-layer';
import { CollectionsPanelTabViewOptions } from '../../../../../data-layer';
import IconButton from '../../../../shared/IconButton';
import CollectionLabel, { testIds as clTestIds } from '../../../../shared/CollectionLabel';
import { makeStyles } from 'tss-react/mui';
import { usePermissions } from '../../../../../hooks/Permissions/usePermissions';
import { PermissionsLevel } from '../../../../../utils/types/permissionsTypes';
import { useConfirm } from '../../../../../hooks';
import { CollectionDeleteConfirm } from '../../../CollectionDeleteConfirm';
import { isEntityDeleted } from '../../../../../utils/generalUtils';
import { ContentCollectionResponse } from '../../../../../API';
import { usePermissionsGuard } from '../../../../../hooks/General/usePermissionsGuard';
import { HomepageOptions } from '../../../../../state/theme';

export const testIds = {
  listItem: 'collection-list-item.list-item',
  draftBadge: clTestIds.draftBadge,
  publishCollectionButton: 'collection-list-item.publish-button',
  editButton: (entityId: string): string => `collection-list-item.edit-button.${entityId}`,
  cloneButton: (entityId: string): string => `collection-list-item.clone-button.${entityId}`,
  layoutsButton: (entityId: string): string => `collection-list-item.layouts-button.${entityId}`,
  editContentButton: 'collection-list-item.edit-content-button',
  editQueryButton: 'collection-list-item.edit-query-button',
  genreBadge: clTestIds.genreBadge,
  similarBadge: clTestIds.similarBadge,
  menuButton: 'collection-list-item.menu-button',
  menuPopover: 'collection-list-item.menu-popover',
  deleteButton: (entityId: string): string => `collection-list-item.delete-button.${entityId}`,
  deleteConfirm: 'collection-list-item.delete-confirm'
};

type CollectionListItemProps = {
  collectionId: string;
  onClick?: (entity: ContentCollectionResponse, whichTab?: CollectionsPanelTabViewOptions) => unknown;
  onEdit?: (entityId: string) => unknown;
  onClone?: (entityId: string) => unknown;
};

const useStyles = makeStyles()((theme) => ({
  listItem: {
    padding: 0,
    borderBottom: `1px solid ${theme.palette.divider}`
  },
  collectionLabel: {
    paddingRight: `calc((36px + ${theme.spacing(2)}) * 3)`,
    flexWrap: 'wrap'
  },
  contentsBadge: {
    ['& .MuiBadge-badge']: {
      pointerEvents: 'none',
      height: 15,
      lineHeight: '100%',
      padding: '0 3px',
      fontSize: 11,
      top: 6,
      right: 7,
      background: theme.palette.secondary.main,
      color: theme.palette.secondary.contrastText
    }
  },
  filterBadge: {
    ['& .MuiBadge-badge']: {
      pointerEvents: 'none',
      fontSize: 10,
      padding: 2,
      right: 9,
      top: 'auto',
      bottom: -6,
      minWidth: 'auto',
      width: 'auto',
      height: 'auto',
      background: theme.palette.secondary.main,
      color: theme.palette.secondary.contrastText,
      ['& > *']: {
        fontSize: 'inherit'
      }
    }
  }
}));

export const CollectionListItem = React.memo(function CollectionListItem({
  collectionId,
  onClick,
  onEdit,
  onClone
}: CollectionListItemProps): JSX.Element {
  const { classes } = useStyles();
  const { t } = useTranslation();
  const { hasAnyPermission, hasPermissions } = usePermissions();
  const { canSave: canSaveGuard, canDelete: canDeleteGuard } = usePermissionsGuard({
    homepageOption: HomepageOptions.COLLECTIONS
  });
  const [collectionMenuAnchorEl, setCollectionMenuAnchorEl] = useState<null | HTMLElement>(null);
  const { confirm } = useConfirm();

  const {
    collections: {
      state: { withRecordById, withAssociatedLayoutsCollection, withMixedCollectionDisplay },
      hook: { remove }
    }
  } = useData();

  const collection = useRecoilValue(withRecordById(collectionId));
  const setAssociatedLayoutsCollection = useSetRecoilState(withAssociatedLayoutsCollection);
  const asMixedType = useRecoilValue(withMixedCollectionDisplay);
  const { DELETE: canDelete } = hasPermissions(collection?.ownerPermissionsGroup);
  const isDeleted = isEntityDeleted(collection);

  if (!collection) return <></>;

  const handleViewAssociatedLayouts = () => {
    closeCollectionMenu();
    setAssociatedLayoutsCollection(collection);
  };

  const handleCollectionClick = () => onClick?.(collection);
  const handleCollectionQueryClick = () => onClick?.(collection, CollectionsPanelTabViewOptions.QUERY_BUILDER);
  const handleEditClick = () => {
    closeCollectionMenu();
    onEdit?.(collection.entityId);
  };
  const handleCloneClick = () => {
    closeCollectionMenu();
    onClone?.(collection.entityId);
  };

  const openCollectionMenu = (event: React.MouseEvent<HTMLButtonElement>) => {
    setCollectionMenuAnchorEl(event.currentTarget);
  };

  const closeCollectionMenu = () => {
    setCollectionMenuAnchorEl(null);
  };

  const handleDeleteConfirm = async () => {
    const result = await confirm({
      confirmColor: 'error',
      confirmText: t('general.confirm_delete'),
      body: <CollectionDeleteConfirm collection={collection} />,
      'data-testid': testIds.deleteConfirm
    });

    if (result) {
      remove(collection.entityId);
    }
  };

  return (
    <ListItem className={classes.listItem}>
      <ListItemButton
        data-collection-id={collection.entityId}
        data-testid={testIds.listItem}
        onClick={handleCollectionClick}
      >
        <ListItemText primary={<CollectionLabel collectionId={collectionId} className={classes.collectionLabel} />} />
      </ListItemButton>
      <ListItemSecondaryAction sx={{ display: 'flex', gap: 1, alignItems: 'center' }}>
        <Badge className={classes.contentsBadge} badgeContent={collection.contents.length} max={999}>
          <IconButton
            data-testid={testIds.editContentButton}
            onClick={handleCollectionClick}
            title={t('collections.edit_collection_content')}
          >
            <AppRegistration fontSize="small" />
          </IconButton>
        </Badge>
        <Badge className={classes.filterBadge} color="success" badgeContent={<Check />} invisible={!collection.query}>
          <IconButton
            data-testid={testIds.editQueryButton}
            onClick={handleCollectionQueryClick}
            title={t('query_builder.edit_collection_query')}
            disabled={asMixedType}
          >
            <FilterAlt fontSize="small" />
          </IconButton>
        </Badge>
        <div>
          <IconButton onClick={openCollectionMenu} data-testid={testIds.menuButton}>
            <MoreVertOutlined fontSize="small" />
          </IconButton>
          <Popover
            data-testid={testIds.menuPopover}
            anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
            transformOrigin={{ vertical: 'top', horizontal: 'right' }}
            anchorEl={collectionMenuAnchorEl}
            keepMounted
            open={!!collectionMenuAnchorEl}
            onClose={closeCollectionMenu}
          >
            <MenuItem
              onClick={handleEditClick}
              data-testid={testIds.editButton(collection.entityId)}
              disabled={isDeleted}
            >
              {t('collections.edit_collection_metadata')}
            </MenuItem>
            <MenuItem
              onClick={handleViewAssociatedLayouts}
              data-testid={testIds.layoutsButton(`.${collection.entityId}`)}
              disabled={isDeleted}
            >
              {t('collections.view_collection_layouts')}
            </MenuItem>
            <MenuItem
              onClick={handleCloneClick}
              data-testid={testIds.cloneButton(collection.entityId)}
              disabled={!hasAnyPermission(PermissionsLevel.UPSERT) || !canSaveGuard}
            >
              {t('collections.clone_collection')}
            </MenuItem>
            <MenuItem
              onClick={handleDeleteConfirm}
              data-testid={testIds.deleteButton(collection.entityId)}
              disabled={!canDelete || !canDeleteGuard || isDeleted}
            >
              {t('collections.delete')}
            </MenuItem>
          </Popover>
        </div>
      </ListItemSecondaryAction>
    </ListItem>
  );
});
