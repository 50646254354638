/* tslint:disable */
/* eslint-disable */
/**
 * CMS API
 * The CMS API!
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import { EmbeddedSpinPremia } from './embedded-spin-premia';
import { EntitlementType } from './entitlement-type';
import { LocalizedFieldBody } from './localized-field-body';
import { TargetPlatform } from './target-platform';
import { UiModuleExperimentGroup } from './ui-module-experiment-group';

/**
 * 
 * @export
 * @interface ProfileUiModuleBody
 */
export interface ProfileUiModuleBody {
    /**
     * 
     * @type {string}
     * @memberof ProfileUiModuleBody
     */
    moduleId?: string;
    /**
     * 
     * @type {string}
     * @memberof ProfileUiModuleBody
     */
    hash?: string;
    /**
     * 
     * @type {string}
     * @memberof ProfileUiModuleBody
     */
    moduleType: ProfileUiModuleBodyModuleTypeEnum;
    /**
     * 
     * @type {Array<TargetPlatform>}
     * @memberof ProfileUiModuleBody
     */
    targetPlatform?: Array<TargetPlatform>;
    /**
     * 
     * @type {LocalizedFieldBody}
     * @memberof ProfileUiModuleBody
     */
    caption?: LocalizedFieldBody;
    /**
     * 
     * @type {Array<EntitlementType>}
     * @memberof ProfileUiModuleBody
     */
    entitlements?: Array<EntitlementType>;
    /**
     * 
     * @type {Array<UiModuleExperimentGroup>}
     * @memberof ProfileUiModuleBody
     */
    experiments?: Array<UiModuleExperimentGroup> | null;
    /**
     * 
     * @type {LocalizedFieldBody}
     * @memberof ProfileUiModuleBody
     */
    title: LocalizedFieldBody;
    /**
     * 
     * @type {string}
     * @memberof ProfileUiModuleBody
     */
    portraitFillImage: string;
    /**
     * 
     * @type {string}
     * @memberof ProfileUiModuleBody
     */
    landscapeFillImage: string;
    /**
     * 
     * @type {string}
     * @memberof ProfileUiModuleBody
     */
    mobileFillImage?: string;
    /**
     * 
     * @type {string}
     * @memberof ProfileUiModuleBody
     */
    ctvFillImage?: string;
    /**
     * 
     * @type {string}
     * @memberof ProfileUiModuleBody
     */
    desktopFillImage?: string;
    /**
     * 
     * @type {string}
     * @memberof ProfileUiModuleBody
     */
    tabletFillImage?: string;
    /**
     * 
     * @type {string}
     * @memberof ProfileUiModuleBody
     */
    header: string;
    /**
     * 
     * @type {string}
     * @memberof ProfileUiModuleBody
     */
    subheader: string;
    /**
     * 
     * @type {string}
     * @memberof ProfileUiModuleBody
     */
    benefit?: string;
    /**
     * 
     * @type {string}
     * @memberof ProfileUiModuleBody
     */
    subscribeCta?: string;
    /**
     * 
     * @type {string}
     * @memberof ProfileUiModuleBody
     */
    signUpCta?: string;
    /**
     * 
     * @type {string}
     * @memberof ProfileUiModuleBody
     */
    signInCta?: string;
    /**
     * 
     * @type {string}
     * @memberof ProfileUiModuleBody
     */
    reactivateCta?: string;
    /**
     * 
     * @type {string}
     * @memberof ProfileUiModuleBody
     */
    adminMiCuenta?: string;
    /**
     * 
     * @type {string}
     * @memberof ProfileUiModuleBody
     */
    subscribeCtaDestination?: string;
    /**
     * 
     * @type {string}
     * @memberof ProfileUiModuleBody
     */
    reactivateCtaDestination?: string;
    /**
     * 
     * @type {string}
     * @memberof ProfileUiModuleBody
     */
    bundleReminderPrimaryOpt?: string;
    /**
     * 
     * @type {string}
     * @memberof ProfileUiModuleBody
     */
    bundleReminderSecondaryOpt?: string;
    /**
     * 
     * @type {string}
     * @memberof ProfileUiModuleBody
     */
    bundleCta?: string;
    /**
     * 
     * @type {string}
     * @memberof ProfileUiModuleBody
     */
    planNameTag?: string;
    /**
     * 
     * @type {string}
     * @memberof ProfileUiModuleBody
     */
    planStatusTag?: string;
    /**
     * 
     * @type {string}
     * @memberof ProfileUiModuleBody
     */
    planSwitchingHeaderCard?: string;
    /**
     * 
     * @type {string}
     * @memberof ProfileUiModuleBody
     */
    planSwitchingCardCta?: string;
    /**
     * 
     * @type {string}
     * @memberof ProfileUiModuleBody
     */
    planSwitchingCta?: string;
    /**
     * 
     * @type {string}
     * @memberof ProfileUiModuleBody
     */
    paymentMethodTag?: string;
    /**
     * 
     * @type {string}
     * @memberof ProfileUiModuleBody
     */
    paymentStatus?: string;
    /**
     * 
     * @type {string}
     * @memberof ProfileUiModuleBody
     */
    paymentStatusAlert?: string;
    /**
     * 
     * @type {string}
     * @memberof ProfileUiModuleBody
     */
    changePaymentMethodCta?: string;
    /**
     * 
     * @type {string}
     * @memberof ProfileUiModuleBody
     */
    cancelSubscriptionCta?: string;
    /**
     * 
     * @type {string}
     * @memberof ProfileUiModuleBody
     */
    legalDisclosurePlanSwitchingCard?: string;
    /**
     * 
     * @type {string}
     * @memberof ProfileUiModuleBody
     */
    changeEmailCta?: string;
    /**
     * 
     * @type {string}
     * @memberof ProfileUiModuleBody
     */
    changePasswordCta?: string;
    /**
     * 
     * @type {string}
     * @memberof ProfileUiModuleBody
     */
    planDetailsCta?: string;
    /**
     * 
     * @type {string}
     * @memberof ProfileUiModuleBody
     */
    cancelInstructionsIap?: string;
    /**
     * 
     * @type {string}
     * @memberof ProfileUiModuleBody
     */
    newPlanNameTag?: string;
    /**
     * 
     * @type {string}
     * @memberof ProfileUiModuleBody
     */
    confirmationPaymentStatusAlert?: string;
    /**
     * 
     * @type {string}
     * @memberof ProfileUiModuleBody
     */
    totalAmountDueTag?: string;
    /**
     * 
     * @type {string}
     * @memberof ProfileUiModuleBody
     */
    activationDateDetail?: string;
    /**
     * 
     * @type {string}
     * @memberof ProfileUiModuleBody
     */
    newBenefitsDetail?: string;
    /**
     * 
     * @type {string}
     * @memberof ProfileUiModuleBody
     */
    planSwitchingRefundTag?: string;
    /**
     * 
     * @type {string}
     * @memberof ProfileUiModuleBody
     */
    planSwitchDetailSubheader?: string;
    /**
     * 
     * @type {string}
     * @memberof ProfileUiModuleBody
     */
    planSwitchLegalDisclosure?: string;
    /**
     * 
     * @type {string}
     * @memberof ProfileUiModuleBody
     */
    planSwitchConfirmCta?: string;
    /**
     * 
     * @type {string}
     * @memberof ProfileUiModuleBody
     */
    planSwitchCancelCta?: string;
    /**
     * 
     * @type {boolean}
     * @memberof ProfileUiModuleBody
     */
    legalCheckbox?: boolean;
    /**
     * 
     * @type {string}
     * @memberof ProfileUiModuleBody
     */
    paymentMethodTitle?: string;
    /**
     * 
     * @type {string}
     * @memberof ProfileUiModuleBody
     */
    paymentMethodDescription?: string;
    /**
     * 
     * @type {string}
     * @memberof ProfileUiModuleBody
     */
    giftCardBalance?: string;
    /**
     * 
     * @type {string}
     * @memberof ProfileUiModuleBody
     */
    topUpCta?: string;
    /**
     * 
     * @type {string}
     * @memberof ProfileUiModuleBody
     */
    redeemTitle?: string;
    /**
     * 
     * @type {string}
     * @memberof ProfileUiModuleBody
     */
    redeemCta?: string;
    /**
     * 
     * @type {EmbeddedSpinPremia}
     * @memberof ProfileUiModuleBody
     */
    spinPremia?: EmbeddedSpinPremia;
    /**
     * 
     * @type {string}
     * @memberof ProfileUiModuleBody
     */
    giftCardBalanceHeading?: string;
    /**
     * 
     * @type {string}
     * @memberof ProfileUiModuleBody
     */
    giftCardBalanceDtc?: string;
}

/**
    * @export
    * @enum {string}
    */
export enum ProfileUiModuleBodyModuleTypeEnum {
    PROFILE = 'PROFILE'
}



