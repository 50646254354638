/* tslint:disable */
/* eslint-disable */
/**
 * CMS API
 * The CMS API!
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import { EntitlementType } from './entitlement-type';
import { LocalizedFieldBody } from './localized-field-body';
import { MenuActionType } from './menu-action-type';
import { MenuIconName } from './menu-icon-name';
import { TargetPlatform } from './target-platform';
import { UiModuleExperimentGroup } from './ui-module-experiment-group';

/**
 * 
 * @export
 * @interface MenuItemUiModuleBody
 */
export interface MenuItemUiModuleBody {
    /**
     * 
     * @type {string}
     * @memberof MenuItemUiModuleBody
     */
    moduleId?: string;
    /**
     * 
     * @type {string}
     * @memberof MenuItemUiModuleBody
     */
    hash?: string;
    /**
     * 
     * @type {string}
     * @memberof MenuItemUiModuleBody
     */
    moduleType: MenuItemUiModuleBodyModuleTypeEnum;
    /**
     * 
     * @type {Array<TargetPlatform>}
     * @memberof MenuItemUiModuleBody
     */
    targetPlatform?: Array<TargetPlatform>;
    /**
     * 
     * @type {LocalizedFieldBody}
     * @memberof MenuItemUiModuleBody
     */
    caption?: LocalizedFieldBody;
    /**
     * 
     * @type {Array<EntitlementType>}
     * @memberof MenuItemUiModuleBody
     */
    entitlements?: Array<EntitlementType>;
    /**
     * 
     * @type {Array<UiModuleExperimentGroup>}
     * @memberof MenuItemUiModuleBody
     */
    experiments?: Array<UiModuleExperimentGroup> | null;
    /**
     * 
     * @type {LocalizedFieldBody}
     * @memberof MenuItemUiModuleBody
     */
    title: LocalizedFieldBody;
    /**
     * 
     * @type {string}
     * @memberof MenuItemUiModuleBody
     */
    titleLokaliseKey?: string;
    /**
     * 
     * @type {MenuActionType}
     * @memberof MenuItemUiModuleBody
     */
    menuActionType: MenuActionType;
    /**
     * 
     * @type {string}
     * @memberof MenuItemUiModuleBody
     */
    targetId: string;
    /**
     * 
     * @type {MenuIconName}
     * @memberof MenuItemUiModuleBody
     */
    iconName?: MenuIconName;
    /**
     * 
     * @type {string}
     * @memberof MenuItemUiModuleBody
     */
    iconImage?: string;
}

/**
    * @export
    * @enum {string}
    */
export enum MenuItemUiModuleBodyModuleTypeEnum {
    MENU_ITEM = 'MENU_ITEM'
}



