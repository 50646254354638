import React, { useMemo } from 'react';
import { makeStyles } from 'tss-react/mui';
import { useData } from '../../../../data-layer';
import AssetImage from '../../../shared/AssetImage';
import { Box, emphasize } from '@mui/material';
import { DisplayAsOptions } from '../../../../utils/types/genericTypes';
import { useRecoilValue } from 'recoil';
import { LocalizedFieldBody } from '../../../../API';
import { getMediaAspectRatio } from '../../../../utils/styleUtils';

interface IEPGChannelImageProps {
  channelLogo: LocalizedFieldBody;
  displayAs?: DisplayAsOptions;
  blocked?: boolean;
}

type StyleProps = Pick<IEPGChannelImageProps, 'displayAs'>;

const useStyles = makeStyles<StyleProps>()((theme, { displayAs }) => ({
  channelImageItem: {
    position: 'relative',
    background: emphasize(theme.palette.background.paper, 0.1),
    fontSize: '0.9rem',
    userSelect: 'none',
    borderRadius: theme.shape.borderRadius,
    aspectRatio: getMediaAspectRatio(displayAs),
    overflow: 'hidden',
    '&:hover': {
      '& > div.title': {
        display: 'unset'
      }
    }
  },
  logo: {
    filter: theme.palette.mode === 'light' ? 'invert(100%) grayscale(100%)' : 'none',
    position: 'absolute',
    inset: 0,
    width: '100%',
    height: '100%',
    objectFit: 'cover',
    aspectRatio: getMediaAspectRatio(displayAs),
    marginTop: theme.spacing(5)
  },
  fallback: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    textAlign: 'center',
    padding: theme.spacing(2),
    height: '100%',
    overflow: 'hidden',
    textOverflow: 'ellipsis'
  }
}));

function EPGChannelImage({ channelLogo, displayAs: displayAsProp }: IEPGChannelImageProps): JSX.Element {
  const {
    collections: {
      state: { withDisplayAs }
    }
  } = useData();

  const displayAsGlobal = useRecoilValue(withDisplayAs);
  const displayAs = displayAsProp || displayAsGlobal;

  const assetProps = useMemo(
    () => (displayAs === DisplayAsOptions.PORTRAIT ? { attribute: 'h', size: 120 } : { attribute: 'w', size: 100 }),
    [displayAs]
  );

  const { classes } = useStyles({ displayAs });

  const fallback = <Box className={classes.fallback} />;

  return (
    <Box className={classes.channelImageItem}>
      {channelLogo ? (
        <AssetImage
          size={assetProps.size}
          attribute={assetProps.attribute}
          path={channelLogo}
          className={classes.logo}
        />
      ) : (
        fallback
      )}
    </Box>
  );
}

export default EPGChannelImage;
